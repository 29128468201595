import React, { useState } from "react";
import { AccordionDetails, AccordionSummary, Typography, Accordion, Checkbox } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useFormik } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Form from "react-bootstrap/Form";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button, TextField } from "@mui/material";
import { db } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { notify } from "../../hooks/notify";

function Delevery({ data }) {
    const formik = useFormik({
        initialValues: {
            states: data.delevery.states,
            free: data.delevery.free,
            activeCities: data.delevery.activeCities,
            home_delevery: {
                is: data.delevery.delevery_home,
                value: data.delevery.home_price
            }
        },
        onSubmit: async (values) => {
            await setDoc(doc(db, "landing-pages", data.id), {
                ...data,
                delevery: {
                    ...data.delevery,
                    states: values.states,
                    free: values.free,
                    activeCities: values.activeCities,
                    delevery_home: values.home_delevery.is,
                    home_price: values.home_delevery.value
                }
            }).then(() => {
                notify(t("Update_Completed_Successfully"))
            });
        },
    });

    const { t, i18n } = useTranslation();
    const [find, setFind] = useState("");


    return (
        <>
            <Accordion className="my-3 rounded rounded-4 bg-white" defaultExpanded>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
                    style={{ backgroundColor: "#2C3E50" }}
                    aria-controls="ceo"
                    id="ceo"
                    className="rounded rounded-4"
                >
                    <Typography className="text-white fw-bold fs-6">{t('Settings')}</Typography>
                </AccordionSummary>
                <AccordionDetails className="">
                    <FormControl fullWidth className="my-3">
                        <InputLabel id="demo-simple-select-label">{t('Country')}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Country"
                            size="small"
                            value={10}
                            disabled
                        >
                            <MenuItem value={10}>{t('Algeria')}</MenuItem>
                        </Select>
                    </FormControl>
                    <Form onSubmit={formik.handleSubmit}>
                        <hr />

                        <div>
                            <FormControlLabel
                                control={<Switch checked={formik.values.free} onChange={() => { formik.setFieldValue("free", !formik.values.free) }} />}
                                label={t('Free_Shipping')}
                                className="mx-2"
                            />
                        </div>
                        <div>
                            {!formik.values.free && <>
                                <FormControlLabel
                                    control={<Switch checked={formik.values.home_delevery.is} onChange={() => {
                                        formik.setFieldValue("home_delevery", {
                                            ...formik.values.home_delevery,
                                            is: !formik.values.home_delevery.is
                                        })
                                    }} />}
                                    label={t('Home_Delivery')}
                                    className="mx-2"
                                />
                                {formik.values.home_delevery.is && <TextField
                                    value={formik.values.home_delevery.value}
                                    onChange={(e) => {
                                        formik.setFieldValue("home_delevery", {
                                            ...formik.values.home_delevery,
                                            value: e.target.value
                                        })
                                    }}
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    placeholder="Extra on home Delevery"
                                    type="number"
                                />}
                            </>}
                        </div>
                        <div>
                            <FormControlLabel
                                control={<Switch checked={formik.values.activeCities} onChange={() => { formik.setFieldValue("activeCities", !formik.values.activeCities) }} />}
                                label={t('Active_Cities')}
                                className="mx-2"
                            />
                        </div>


                        <hr />
                        <div className={i18n.language === "en" ? "text-end" : "text-start"}>
                            <Button type="submit" variant="contained" className="bg-success">
                                {t('Update')}
                            </Button>
                        </div>
                    </Form>
                </AccordionDetails>
            </Accordion>
            {!formik.values.free && <Accordion className="my-3 rounded rounded-4 bg-white" defaultExpanded>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
                    style={{ backgroundColor: "#2C3E50" }}
                    aria-controls="ceo"
                    id="ceo"
                    className="rounded rounded-4"
                >
                    <Typography className="text-white fw-bold fs-6">{t('Pricing')}</Typography>
                </AccordionSummary>
                <AccordionDetails className="">
                    <div className="my-3">
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            placeholder={t('Search')}
                            value={find}
                            onChange={(e) => { setFind(e.target.value) }}
                        />
                    </div>
                    <TableContainer sx={{ height: "500px", overflowY: 'auto' }} className="custom-scrollbar" component={Paper}>
                        <Table size="small"   aria-label="a dense table">
                            <TableHead >
                                <TableRow >
                                    <TableCell className={"text-center"}></TableCell>
                                    <TableCell className={"text-center"}>{t('Code')}</TableCell>
                                    <TableCell className={"text-center"}>{t('Name')}</TableCell>
                                    <TableCell className={"text-center"}>{t('Price')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {formik.values.states.filter(s => {
                                    return s.wilaya_code.includes(find);
                                }).map((row, index) => (
                                    <TableRow
                                        key={row}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell className={"text-center"} component="th" scope="row">
                                            <Checkbox checked={!row.hide} onClick={() => {
                                                const updatedStates = formik.values.states.map(state => {
                                                    if (state.wilaya_code === row.wilaya_code) {
                                                        return { ...state, hide: !state.hide };
                                                    }
                                                    return state;
                                                });
                                                formik.setFieldValue("states", updatedStates);
                                            }} style={{ marginLeft: "-15px" }} />
                                        </TableCell>
                                        <TableCell className={"text-center"}>{row.wilaya_code}</TableCell>
                                        <TableCell className={"text-center"}>{row.wilaya_name_ascii}</TableCell>
                                        <TableCell className={"text-center"}>
                                            <input
                                                onChange={(e) => {
                                                    const updatedStates = formik.values.states.map(state => {
                                                        if (state.wilaya_code === row.wilaya_code) {
                                                            return { ...state, price: parseInt(e.target.value) };
                                                        }
                                                        return state;
                                                    });
                                                    formik.setFieldValue("states", updatedStates);
                                                }}
                                                value={row.price}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    <div className={i18n.language === "en" ? "text-end mt-3" : "text-start mt-3"}>
                        <Button onClick={() => formik.submitForm()} variant="contained" className="bg-success">
                            {t('Update')}
                        </Button>
                    </div>
                </AccordionDetails>
            </Accordion>}
        </>
    );
}

export default Delevery;
