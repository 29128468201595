import React, { useEffect, useState, useRef } from "react";
import { AccordionDetails, AccordionSummary, Typography, Accordion, Button } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useFormik } from "formik";
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { notify } from "../../hooks/notify";

function Offers({ data }) {
    const { t, i18n } = useTranslation();

    const [isAddingOption, setIsAddingOption] = useState(false)

    const deleteRowByIndex = (indexToDelete) => {
        formik2.setValues((prevRows) => prevRows.filter((_, index) => index !== indexToDelete))
    };

    const addRow = (newRow) => {
        formik2.setValues((prevRows) => [...prevRows, newRow])
    };

    const formik = useFormik({
        initialValues: {
            qty: 0,
            price: 0,
        },
        onSubmit: async (values) => {

        },
    });

    const formik2 = useFormik({
        initialValues: data.offers,
        onSubmit: async (values) => {
            await setDoc(doc(db, "landing-pages", data.id), {
                ...data,
                offers: values
            }).then(() => {
                notify(t("Update_Completed_Successfully"))
            });
        },
    });

    return (
        <Accordion className="my-3 rounded rounded-4 bg-white" defaultExpanded>
            <AccordionSummary
                expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
                style={{ backgroundColor: "#2C3E50" }}
                aria-controls="ceo"
                id="ceo"
                className="rounded rounded-4"
            >
                <Typography className="text-white fw-bold fs-6">{t('Offers')}</Typography>
            </AccordionSummary>
            <AccordionDetails className="">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell className={"text-center"}>{t('Quantity')}</TableCell>
                                <TableCell className={"text-center"}>{t('Price')}</TableCell>
                                <TableCell className={"text-center"}>{t('Actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {formik2.values.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell className={"text-center"} component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell className={"text-center"} >{row.qty}</TableCell>
                                    <TableCell className={"text-center"}>{row.price}</TableCell>
                                    <TableCell className={"text-center"}>
                                        <div
                                            style={{ height: "30px" }}
                                            className="rounded rounded-circle text-white d-flex justify-content-center"

                                        >
                                            {/* Content for the div in the top right */}
                                            <DeleteIcon className="text-danger" onClick={() => deleteRowByIndex(index)} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell >
                                    {!isAddingOption ? <AddCircleIcon sx={{ color: "green", marginLeft: "-8px" }} onClick={() => setIsAddingOption(true)} /> : formik2.values.length + 1}
                                </TableCell>
                                {isAddingOption && <>
                                    <TableCell component="th" scope="row">
                                        <input
                                            id="qty"
                                            name="qty"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.qty}
                                            type="number"
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <input
                                            id="price"
                                            name="price"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.price}
                                            type="number"
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <CancelIcon onClick={() => setIsAddingOption(false)} className="text-danger" />
                                        <CheckCircleIcon onClick={() => {
                                            addRow({
                                                qty: formik.values.qty,
                                                price: formik.values.price
                                            });
                                            formik.resetForm();
                                            setIsAddingOption(false)
                                        }} sx={{ color: "green" }} />

                                    </TableCell>
                                </>}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={i18n.language === "en" ? "text-end mt-3" : "text-start mt-3"}>
                    <Button onClick={() => formik2.submitForm()} variant="contained" className="bg-success">
                        {t('Update')}
                    </Button>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

export default Offers;
