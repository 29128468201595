import React, { useEffect, useState, useRef } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import { Box, Tab, Tabs } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import PageEditor from "../../../components/design";
import Options from "../../../components/options";
import Offers from "../../../components/offers";
import Delevery from "../../../components/delevery";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InventoryIcon from '@mui/icons-material/Inventory';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ApiIcon from '@mui/icons-material/Api';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Apis from "../../../components/apis";
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
function Edit() {
    const { t, i18n } = useTranslation();
    const { landingsId } = useParams();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(undefined);
    const iframeRef = useRef(null);
    const handleLoad = () => {
        // Once the iframe content is loaded, set loading to false
        setLoading(false);
    };
    const [currentTap, setCurrentTap] = useState("design")
    useEffect(() => {
        // Function to fetch data from an API
        onSnapshot(doc(db, "landing-pages", landingsId), (doc) => {
            setData({
                id: landingsId,
                ...doc.data()
            })
        });
    }, []);
    const navigate = useNavigate();

    const handleGoBack = () => {
      navigate(-1); // This will navigate back to the previous page
    };

    return (
        <>
            {!!data && <div dir={i18n.language === "ar" ? "rtl" : ""} className="d-flex flex-column" style={{ minHeight: "100vh", minWidth: "100vw", fontWeight: "bold" }}>
                <div className=" d-flex align-items-center" style={{
                    height: "5vh",
                    backgroundColor: "#2C3E50",
                    borderBottom: "2px solid #2C3E50",
                }}>
                    <div className={i18n.language === "ar" ? "d-flex w-100" : "d-flex justify-content-start w-100"} onClick={handleGoBack}>
                        {i18n.language === "ar" && <RedoIcon className="text-white mx-2" />}
                        {i18n.language !== "ar" && <UndoIcon className="text-white mx-2" />}
                        <p className="text-white p-0 m-0">{" "}{t('Back')}</p>
                        
                    </div>

                </div>

                <div className="flex-grow-1 row m-0 p-0">

                    <div className="col-sm-5 col-12" style={i18n.language === "ar" ? { borderLeft: "1px solid black", backgroundColor: "#F3F7FD" } : { borderRight: "1px solid   black", backgroundColor: "#F3F7FD" }}>
                        {/*<h4 className="">{t('Design_Your_Landing_Page')} &#128071;</h4>*/}
                        <div style={{ height: "95vh" }}>
                            <Box className="" sx={{ backgroundColor: "#F3F7FD", height: "6vh", borderBottom: "2px solid black" }}>
                                <Tabs
                                    //value={value}
                                    // onChange={handleChange}
                                    className=" w-100 py-2"
                                    variant="scrollable"
                                    scrollButtons={false}
                                    aria-label="scrollable prevent tabs example"
                                >
                                    <Tab onClick={() => setCurrentTap("design")} label={<p className="p-0 m-0 fs-5 fw-bold"><DesignServicesIcon style={{ color: "#00BF62" }} className={i18n.language === "en" ? "me-2" : "ms-2"} />{t('Design')}</p>} style={currentTap === "design" ? { backgroundColor: "#2C3E50", color: "white" } : {}} />
                                    <Tab onClick={() => setCurrentTap("stock")} label={<p className="p-0 m-0 fs-5 fw-bold"><InventoryIcon style={{ color: "#00BF62" }} className={i18n.language === "en" ? "me-2" : "ms-2"} />{t('Stock')}</p>} style={currentTap === "stock" ? { backgroundColor: "#2C3E50", color: "white" } : {}} />
                                    <Tab onClick={() => setCurrentTap("delevery")} label={<p className="p-0 m-0 fs-5 fw-bold"><LocalShippingIcon style={{ color: "#00BF62" }} className={i18n.language === "en" ? "me-2" : "ms-2"} />{t('Delevery')}</p>} style={currentTap === "delevery" ? { backgroundColor: "#2C3E50", color: "white" } : {}} />
                                    <Tab onClick={() => setCurrentTap("offers")} label={<p className="p-0 m-0 fs-5 fw-bold"><OnlinePredictionIcon style={{ color: "#00BF62" }} className={i18n.language === "en" ? "me-2" : "ms-2"} />{t('Offers')}</p>} style={currentTap === "offers" ? { backgroundColor: "#2C3E50", color: "white" } : {}} />
                                    <Tab onClick={() => setCurrentTap("apis")} label={<p className="p-0 m-0 fs-5 fw-bold"><ApiIcon style={{ color: "#00BF62" }} className={i18n.language === "en" ? "me-2" : "ms-2"} />{t('Apis')}</p>} style={currentTap === "apis" ? { backgroundColor: "#2C3E50", color: "white" } : {}} />
                                </Tabs>
                            </Box>
                            <Box className="custom-scrollbar p-2" sx={{ overflow: "auto", height: "87vh" }}>
                                {currentTap === "design" && <PageEditor dataToBeEdited={data} setLoading={setLoading} data={data} setData={setData} iframeRef={iframeRef} />}
                                {currentTap === "stock" && <Options data={data} />}
                                {currentTap === "offers" && <Offers data={data} />}
                                {currentTap === "delevery" && <Delevery data={data} />}
                                {currentTap === "apis" && <Apis data={data} />}
                            </Box>

                        </div>
                    </div>
                    <div className="col-sm-7 col-12 d-flex">

                        <div className="flex-grow-1 d-flex justify-content-center align-items-center">

                            <div
                                className="d-flex justify-content-center align-items-center"
                                style={{ backgroundColor: "black", width: "450px", height: "850px", borderRadius: "30px" }}>
                                <div style={{ backgroundColor: "white", width: "420px", height: "800px", borderRadius: "30px" }}>
                                    <iframe
                                        ref={iframeRef}
                                        title="External Site"
                                        src={`https://${"abc"}.dokean.store`}
                                        className="mx-auto"
                                        style={{ borderRadius: "20px", width: "100%", height: "100%", display: loading ? "none" : "", border: "1px solid black" }}
                                        onLoad={handleLoad}
                                    />
                                    {loading && (
                                        <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                                            <CircularProgress />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
}

export default Edit;
