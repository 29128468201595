import React, { useEffect, useState, useRef } from "react";
import { AccordionDetails, AccordionSummary, Typography, Accordion, Button } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useFormik } from "formik";
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { notify } from "../../hooks/notify";

function Apis({ data }) {
    const { t, i18n } = useTranslation();
    const formik2 = useFormik({
        initialValues: {
            facebook_pixel: data.apis.facebook_pixel,
            google_sheet: data.apis.google_sheet
        },
        onSubmit: async (values) => {
            await setDoc(doc(db, "landing-pages", data.id), {
                ...data,
                apis: {
                    ...data.apis,
                    facebook_pixel: values.facebook_pixel,
                    google_sheet: values.google_sheet
                }

            }).then(() => {
                notify(t("Update_Completed_Successfully"))
            });
        },
    });

    return (
        <>
            <Accordion className="my-3 rounded rounded-4 bg-white" defaultExpanded>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
                    style={{ backgroundColor: "#2C3E50" }}
                    aria-controls="ceo"
                    id="ceo"
                    className="rounded rounded-4"
                >
                    <Typography className="text-white fw-bold fs-6">{t('Facebook pixel')}</Typography>
                </AccordionSummary>
                <AccordionDetails className="">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell  className={"text-center"}>uid</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formik2.values.facebook_pixel.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell className="text-center" component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell ><input className="w-100" value={row} onChange={(e) => formik2.setFieldValue('facebook_pixel', [e.target.value])} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={i18n.language === "en" ? "text-end mt-3" : "text-start mt-3"}>
                        <Button onClick={() => formik2.submitForm()} variant="contained" className="bg-success">
                            {t('Update')}
                        </Button>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion className="my-3 rounded rounded-4 bg-white" defaultExpanded>
                <AccordionSummary
                    expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
                    style={{ backgroundColor: "#2C3E50" }}
                    aria-controls="ceo"
                    id="ceo"
                    className="rounded rounded-4"
                >
                    <Typography className="text-white fw-bold fs-6">{t('google sheet')}</Typography>
                </AccordionSummary>
                <AccordionDetails className="">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell  className={"text-center"}>url</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {formik2.values.google_sheet.map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell className="text-center" component="th" scope="row">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell ><input className="w-100" value={row} onChange={(e) => formik2.setFieldValue('google_sheet', [e.target.value])} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={i18n.language === "en" ? "text-end mt-3" : "text-start mt-3"}>
                        <Button onClick={() => formik2.submitForm()} variant="contained" className="bg-success">
                            {t('Update')}
                        </Button>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
}

export default Apis;
