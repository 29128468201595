import React, { useState } from "react";
import { AccordionDetails, AccordionSummary, Typography, Accordion, Button } from "@mui/material";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useFormik } from "formik";
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Form from "react-bootstrap/Form";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { notify } from "../../hooks/notify";

function Options({ data }) {
    const { t, i18n } = useTranslation();
    const [isAddingColor, setIsAddingColor] = useState(false);
    const [newColor, setNewColor] = useState("#000")


    const [indexToEdit, setIndexToEdit] = useState(0)
    const [isAddingOption, setIsAddingOption] = useState(false)
    const addColorToRow = (name, newColor) => {
        formik2.setValues((prevRows) =>
            prevRows.map((row) =>
                row.name === name
                    ? { ...row, colors: [...row.colors, newColor] }
                    : row
            )
        );
        setNewColor("#000")
    };

    const deleteRowByIndex = (indexToDelete) => {
        formik2.setValues((prevRows) => prevRows.filter((_, index) => index !== indexToDelete));
    };

    const addRow = (newRow) => {
        formik2.setValues((prevRows) => [...prevRows, newRow]);
    };

    const formik = useFormik({
        initialValues: {
            name: "",
            qty: 1,
        },
        onSubmit: async (values) => {

        },
    });

    const formik2 = useFormik({
        initialValues: data.options,
        onSubmit: async (values) => {
            await setDoc(doc(db, "landing-pages", data.id), {
                ...data,
                options: values
            }).then(() => {
                notify(t("Update_Completed_Successfully"))
            });
        },
    });

    return (
        <Accordion className="my-3 rounded rounded-4 bg-white" defaultExpanded>
            <AccordionSummary
                expandIcon={<ArrowDownwardIcon style={{ color: "white" }} />}
                style={{ backgroundColor: "#2C3E50" }}
                aria-controls="ceo"
                id="ceo"
                className="rounded rounded-4"
            >
                <Typography className="text-white fw-bold fs-6">{t('Options')}</Typography>
            </AccordionSummary>
            <AccordionDetails className="">
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell className={"text-center"}>{t('Name')}</TableCell>
                                <TableCell className={"text-center"}>{t('Quantity')}</TableCell>
                                <TableCell className={"text-center"}>{t('Colors')}</TableCell>
                                <TableCell className={"text-center"}>{t('Actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {formik2.values.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell className={"text-center"} component="th" scope="row">
                                        {index + 1}
                                    </TableCell>
                                    <TableCell className={"text-center"}>{row.name}</TableCell>
                                    <TableCell className={"text-center"}>{row.qty}</TableCell>
                                    <TableCell className={"text-center"}>
                                        <div className="d-flex align-items-center justify-content-center">
                                            {row.colors.map((color, index) => (
                                                <div
                                                    key={index}
                                                    className="rounded rounded-circle me-1"
                                                    style={{
                                                        height: "30px",
                                                        width: "30px",
                                                        backgroundColor: color,
                                                    }}
                                                />
                                            ))}
                                            {
                                                !isAddingColor ?
                                                    <div
                                                        onClick={() => { setIsAddingColor(true); setIndexToEdit(index) }}
                                                        className="rounded rounded-circle me-1 d-flex justify-content-center align-items-center"
                                                        style={{
                                                            height: "30px",
                                                            width: "30px",
                                                            backgroundColor: "white",
                                                            border: '2px dashed black'
                                                        }}
                                                    >
                                                        <span className='fw-bolder'>+</span>
                                                    </div> :
                                                    indexToEdit === index && <div className='d-flex align-items-center'>
                                                        <CloseIcon onClick={() => { setIsAddingColor(false) }} />
                                                        <Form.Control
                                                            value={newColor}
                                                            onChange={(e) => setNewColor(e.target.value)}
                                                            style={{
                                                                height: "30px",
                                                                width: "30px",
                                                            }} className="mx-1 rounded rounded-circle" type="color" />
                                                        <DoneIcon onClick={() => addColorToRow(row.name, newColor)} />
                                                    </div>
                                            }
                                        </div>

                                    </TableCell>
                                    <TableCell >
                                        <div
                                            style={{ height: "30px"}}
                                            className="rounded rounded-circle text-white d-flex justify-content-center"

                                        >
                                            {/* Content for the div in the top right */}
                                            <DeleteIcon className="text-danger" onClick={() => deleteRowByIndex(index)} />
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell >
                                    {!isAddingOption ? <AddCircleIcon sx={{ color: "green", marginLeft: "-8px" }} onClick={() => setIsAddingOption(true)} /> : formik2.values.length + 1}
                                </TableCell>
                                {isAddingOption && <>
                                    <TableCell component="th" scope="row">
                                        <input
                                            id="name"
                                            name="name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.name}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <input
                                            id="qty"
                                            name="qty"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.qty}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">

                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <CancelIcon onClick={() => setIsAddingOption(false)} className="text-danger" />
                                        <CheckCircleIcon onClick={() => {
                                            addRow({
                                                name: formik.values.name,
                                                qty: formik.values.qty,
                                                colors: []
                                            });
                                            formik.resetForm();
                                            setIsAddingOption(false)
                                        }} sx={{ color: "green" }} />

                                    </TableCell>
                                </>}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={i18n.language === "en" ? "text-end mt-3" : "text-start mt-3"}>
                    <Button onClick={() => formik2.submitForm()} variant="contained" className="bg-success">
                        {t('Update')}
                    </Button>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

export default Options;
