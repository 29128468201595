import firebase from "./firebase";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { Provider } from "react-redux";
import store from "./redux/configureStore";
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
// import i18n (needs to be bundled ;)) 
import './i18n';
/*
console.log = function () { };
console.error = function () { };
console.warn = function () { };
*/

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ToastContainer />
    <App />
  </Provider>
);
